// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2022 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import SetAccessRequestDTO from '../../dto/resource/SetAccessRequestDTO';

export default abstract class SetAccessRequestModel extends BaseModel<SetAccessRequestDTO> {
  /**
  * @type {int64}
  */
  get userId() { return this.dto.userId; }
  set userId(value) { this.dto.userId = value; }
  /**
  */
  get accessCode() { return this.dto.accessCode; }
  set accessCode(value) { this.dto.accessCode = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    let value: any;
    value = Number.parseFloat(queries.userId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.userId = value;
    this.dto.accessCode = queries.accessCode;
  }
}
