



































































import Vue from 'vue';
import SetAccessRequestViewModel from '@/src/services/viewModel/resource/SetAccessRequestViewModel';

export default Vue.extend({
  layout: ['layout-page'],
  auth: true,
  data: () => ({
    requestAccessCode: new SetAccessRequestViewModel(),
    isUserVerified: false,
    accessCodeErrorMessage: undefined as any,
    isLoading: false,
  }),
  computed: {
  },
  mounted() {
    this.isUserVerified = this.$service.api.users.getHasAccessToPrivateContent();
  },
  methods: {
    getAccessCode() {
      window.location.href = 'https://www.smartglarus.com/';
    },
    async sendAccessCode() {
      this.isLoading = true;
      try {
        const user = await this.$auth.getUser();
        this.requestAccessCode.userId = user?.id!;
        await this.$service.api.users.setLearnitAccess(user?.id!, this.requestAccessCode);
        await this.$auth.refresh();
        this.isUserVerified = this.$service.api.users.getHasAccessToPrivateContent();
      } catch (error : any) {
        if (error.status === 400) {
          this.accessCodeErrorMessage = this.$t('page.accessCode.alert.accessCodeIsWrong');
        } else {
          this.accessCodeErrorMessage = error.message;
        }
        const { accessCodeField } = this.$refs;
        const inputField = (accessCodeField as Vue).$refs.input;
        (inputField as HTMLElement).focus();
      }
      this.isLoading = false;
    },
  },
});
